import React,{useReducer} from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';
import { SERVICES_INMUEBLE_TYPE_AGREGAR, SERVICES_INMUEBLE_TYPE_ELIMINAR, SERVICES_INMUEBLE_TYPE_ERROR, SERVICES_INMUEBLE_TYPE_LOADING, SERVICES_INMUEBLE_TYPE_TRAER_TODAS } from '../../types';
import { ServiceInImuebleTypeContext } from './serviceInInmuebleTypeContext';
import serviceInInmuebleTypeReducer from './serviceInInmuebleTypeReducer';

const ServiceInInmuebleTypeState = (props) => {

    const INITIAL_STATE = {
        data:[],
        loading:false,
        error:null
    }

    const [state, dispatch] = useReducer(serviceInInmuebleTypeReducer, INITIAL_STATE);

    const findByInmuebleTypeId = async inm_type_id => {
        try {
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_LOADING
            })
            if(localStorage.getItem("token")){
                tokenAuth(localStorage.getItem("token"));
            }
            const res = await clienteAxios.get("/services-inmueble-types?inmueble_type_id="+inm_type_id);
            const {data:{data:services}} = res;
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_TRAER_TODAS,
                payload:services
            })
        } catch (error) {
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_ERROR,
                payload:error.response.data
            })
        }
    }

    const assign = async (service_id, inm_type_id) => {
        try {
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_LOADING
            })
            if(localStorage.getItem("token")){
                tokenAuth(localStorage.getItem("token"));
            }
            const res = await clienteAxios.post("/services-inmueble-types",{
                inmueble_type_id:inm_type_id,
                service_id
            });
            const {data:{data}} = res;
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_AGREGAR,
                payload:data
            })
        } catch (error) {
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_ERROR,
                payload:error.response.data
            })
        }
    }

    const remove = async (service_id) => {
        try {
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_LOADING
            })
            if(localStorage.getItem("token")){
                tokenAuth(localStorage.getItem("token"));
            }
            const res = await clienteAxios.delete("/services-inmueble-types/"+service_id);
            const {data:{data}} = res;
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_ELIMINAR,
                payload:data
            })
        } catch (error) {
            dispatch({
                type:SERVICES_INMUEBLE_TYPE_ERROR,
                payload:error.response.data
            })
        }
    }


    return (
        <ServiceInImuebleTypeContext.Provider value={{
            data:state.data,
            loading:state.loading,
            error:state.error,
            findByInmuebleTypeId,
            assign,
            remove
        }}>
            {props.children}
        </ServiceInImuebleTypeContext.Provider>
    );
}

export default ServiceInInmuebleTypeState;