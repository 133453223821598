import React, { useContext, useEffect, useState } from 'react';
import FormDatoTecnico from '../../components/forms/formDatoTecnico';
import FormPropiedad from '../../components/forms/formPropiedad';
import FormServicio from '../../components/forms/formServicio';
import FormHeader from '../../components/forms/formHeader';
import FormImagenes from '../../components/forms/formImagenes';
import { PropiedadContext } from "../../context/propiedades/propiedadesContext";
import { DatoTecnicoContext } from "../../context/datoTecnico/datoContext";
import { ServiciosContext } from "../../context/servicios/serviciosContext";
import { ImagenesContext } from "../../context/imagenes/imagenesContext";
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import Loader from '../../components/Loader/Loader';

const NewPropiedad = () => {
    const {loading,agregar} = useContext(PropiedadContext);
    const history = useHistory();

    const [formValues, setFormValues] = useState({
        propiedad:{
            idCategoria:"",
            idOperacion:"",
            idPartido:"",
            idLocalidad:"",
            idBarrio:"",
            direccion:"",
            descripcion:"",
            precio:"",
            estado:"Disponible",
            mostrarEstado:"si",
            moneda:"dolar",
            lat:'',
            lon:'' 
        },
        datos_tecnicos:{
            s_cubierta:'',
            s_semicubierta:'',
            s_terreno:'',
            s_total:'',
            pileta:'Si',
            cochera:'Si',
            dormitorios:'',
            u_medida:'metros cuadrados',
            baños:'0'
        },
        servicios:{
            luz:'',
            agua:'',
            calefaccion:'',
            telefono:'',
            gas:'',
            internet:'',
        }
    });

    const handleChangePropiedad = e =>{
        setFormValues({
            ...formValues,
            propiedad:{
                ...formValues.propiedad,
                [e.target.name]:e.target.value
            }
        })
    };

    const handleChangeTecnico = e =>{
        setFormValues({
            ...formValues,
            datos_tecnicos:{
                ...formValues.datos_tecnicos,
                [e.target.name]:e.target.value
            }
        })
    };

    const handleChangeServicio = e =>{
        setFormValues({
            ...formValues,
            servicios:{
                ...formValues.servicios,
                [e.target.name]:e.target.value
            }
        })
    };

    const enviar = async () =>{
        //validar el form
        const validation = validarForm();
        if(typeof validation == "string"){
            Swal.fire("Error",`El campo ${validation} es obligatorio`,"error");
            return;
        }

        //enviar datos al server
        await agregar(formValues);
        Swal.fire("Listo","Propiedad guardada", "success");
    }

    const validarForm = ()=>{
        for (const key in formValues.propiedad) {
            if(formValues.propiedad[key] == '') return key;
        }
        for (const key in formValues.datos_tecnicos) {
            if(formValues.datos_tecnicos[key] == '') return key;
        }
        for (const key in formValues.servicios) {
            if(formValues.servicios[key] == '') return key;
        }
        return true;
    }

    return (
        <div className="container pt-5">
            <FormPropiedad formValues={formValues.propiedad} allFormValues={formValues} handleChange={handleChangePropiedad} setFormValues={setFormValues}/>
            <FormDatoTecnico formValues={formValues.datos_tecnicos} handleChange={handleChangeTecnico}/>
            <FormServicio formValues={formValues.servicios} handleChange={handleChangeServicio}/>
            <div className="col-12 text-center mb-4">
                {loading ? <p>Guardando datos de la propiedad...</p> : <button type="button" className="btn btn-success" onClick={enviar}>Cargar propiedad</button>}
            </div>

            <FormHeader/>
            <FormImagenes/>
        </div>
    );
}
 
export default NewPropiedad;