import React,{useState,useContext, useEffect} from 'react';
import Swal from 'sweetalert2';
import { ServiceOptionsContext } from '../../context/serviceOptions/ServiceOptionsContext';

const FormServiceActions = ({entity}) => {

    const [formValues, setFormValues] = useState({
        id:null,
        service_id:null,
        value:'',
        is_default:0
    });
    const {create:createOption,update:updateOption} = useContext(ServiceOptionsContext);

    useEffect(() => {
      if(entity){
        setFormValues({
          id:entity.id,
          service_id:entity.service_id,
          value:entity.value,
          is_default:entity.is_default
        });
      }
    }, [])

    const handleChange = event=>{
        setFormValues({
            ...formValues,
            [event.target.name]:event.target.value
        })
    };

    const handleSubmit = async event=>{
        event.preventDefault();
        switch (entity.action) {
          case 'create':
            await create();
            break;
          case 'edit':
            await update();
            break;
          default:
            break;
        }
        Swal.fire('Listo', 'Recurso guardado correctamente', 'success').then(()=>{
          document.location.reload();
        });
    }

    const create = async () => {
      await createOption(formValues);
      return;
    }

    const update = async () => {
      await updateOption(formValues.id, formValues);
    }

    return (
      formValues.service_id === null ? null :
      <form className="form-group" onSubmit={handleSubmit}>
        <input type="hidden" value={formValues.service_id} name="service_id"/>
        <div className='col-12 my-2'>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Valor</div>
            </div>
            <input type="text" className="form-control" name="value" onChange={handleChange} value={formValues.value} required placeholder="Ingresar el valor de la opción"/>
          </div>
        </div>
        <div className='col-12 my-2'>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">Es default</div>
            </div>
            <select className="form-control" name="is_default" onChange={handleChange} defaultValue={formValues.is_default} required>
              <option value="0">No</option>
              <option value="1">Si</option>
            </select>
          </div>
        </div>
        <div className="col-12">
          <input type="submit" className="btn btn-info mt-4 w-100" value="Guardar"/>
        </div>
      </form>
    );
}
 
export default FormServiceActions;