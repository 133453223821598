import React, { useContext, useEffect, useState } from 'react';
import { ServicesContext } from '../../context/services/servicesContext';
import { ServiceInImuebleTypeContext } from '../../context/servicesInInmublesType/serviceInInmuebleTypeContext';
import Loader from '../Loader/Loader';
import ServiceItem from '../ServiceItem';

const FormServicesInImbuebleType = ({ category_id }) => {

    const [servicesSelected, setServicesSelected] = useState([]);
    const {data:services,loading,error,find:findAllServices} = useContext(ServicesContext);
    const {data:services_in_inm_type, loading:loading_services_inm_type, error:error_services_inm_type, findByInmuebleTypeId, assign, remove} = useContext(ServiceInImuebleTypeContext);

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
      if(services.length>0 && services_in_inm_type.length>0){
        let services_selected = [];
        services_in_inm_type.forEach(ser => {
            services_selected.push({
                inmueble_type_id:ser.inmueble_type_id,
                service_id:ser.inm_service_id,
                id:ser.id
            })
        });
        setServicesSelected(services_selected);
      }
    }, [services,services_in_inm_type])
    
    
    
    const getData = async()=>{
        await findAllServices();
        await findByInmuebleTypeId(category_id);
        return;
    }
    

    const handleChange = async e => {
        const { target: { value: service_id } } = e;
        if (serviceAlreadySelected(service_id)) {
            const {id} = servicesSelected.filter(item => item.service_id == service_id)[0];
            await remove(id);
            removeServiceSelected(service_id);
        } else {
            await assign(service_id, category_id);
            setServicesSelected([
                ...servicesSelected,
                {
                    inmueble_type_id: category_id,
                    service_id
                }
            ])
        }
    }

    const serviceAlreadySelected = id => {
        let selected = false;
        servicesSelected.map(ser => {
            if (ser.service_id == id) selected = true;
        })
        return selected;
    }

    const removeServiceSelected = id => {
        const currentServices = servicesSelected.filter(ser => ser.service_id != id);
        setServicesSelected(currentServices);
    }

    const serviceIsAssigned = service_id => {
        const filters = servicesSelected.filter(ser=>ser.service_id == service_id);
        return filters.length>0;
    }

    if(loading_services_inm_type){
        return <Loader/>
    }

    return <div className='row'>
        <div className='col-12'>
            <h4>Servicios asignados</h4>
            <hr />
        </div>
        {services.map(service => (
            <div className='col-12 col-md-4' key={service.id}>
                <ServiceItem service_name={service.name} value={service.id} isAssigned={serviceIsAssigned(service.id)} handleChange={handleChange} />
            </div>
        ))}
    </div>
}


export default FormServicesInImbuebleType;