import React, { useContext, useEffect } from 'react';
import ModalDispatcher from '../Modal/ModalDispatcher';
import { ServiceOptionsContext } from '../../context/serviceOptions/ServiceOptionsContext';
import './style/Item.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ServiceOptionItem = ({option_item,handleEditClick}) => {

  
  const {selected, read, remove:deleteOption} = useContext(ServiceOptionsContext);

  useEffect(() => {
    if(selected){
      handleEditClick(selected.id, selected.service_id, selected.value, selected.is_default);
    }
  }, [selected])
  

  const remove = id => {
    MySwal.fire({
      title: '¿Desea eliminar la opción?',
      text: "Puede que tengas propiedades con esta opción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar!',
    }).then(async(result) => {
        if (result.value) {
            await deleteOption(id);
            Swal.fire('Listo','La opción ha sido eliminada','success').then(()=>{
              document.location.reload();
            });
        }
    })
  }

  const edit = id => {
    read(id);
  }

  return (
    <>
      <div className='service_options_item'>
        <span>{option_item.value}</span>
        <div className='service_options_item_actions'>
          <ModalDispatcher class_name="btn btn-outline-secondary mx-2" button_text="Editar" handleClickFunction={()=>edit(option_item.id)}></ModalDispatcher>
          <button onClick={() => remove(option_item.id)} className="btn btn-outline-danger mx-2">Eliminar</button>
        </div>
      </div>
    </>
  );
}

export default ServiceOptionItem;