import React, { useEffect } from 'react';

const ModalContent = props => {

  useEffect(() => {
    const myModal = document.getElementById('exampleModal');
    myModal.addEventListener('hidden.bs.modal', function (event) {
      props.onCloseHandler();
    })
  }, [])
  
  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title fs-5" id="exampleModalLabel">{props.modal_title ? props.modal_title : ''}</h4>
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
          <div className="modal-body">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
};

export default ModalContent;