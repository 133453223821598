import React, { useReducer } from 'react';
import FieldTypeReducer from './FieldTypeReducer';
import {FieldTypeContext} from './FieldTypeContext';
// import clienteAxios from '../../config/axios';
import { FIELD_TYPE_LOADING, FIELD_TYPE_TRAER_TODAS } from '../../types';

const FieldTypeState = props => {
  const INITIAL_STATE = {
    data:[],
    loading:false,
    error:null
  }

  const [state, dispatch] = useReducer(FieldTypeReducer, INITIAL_STATE);

  const find = async () => {
    return new Promise((resolve, reject) => {
      dispatch({
        type:FIELD_TYPE_LOADING
      })
      // reemplazar por consulta a ms
      const field_types = [
        {
          id:1,
          code:'TEXTBOX',
          name:'Text box'
        },
        {
          id:2,
          code:'SELECT',
          name:'Selector'
        },
        {
          id:3,
          code:'COMMENT',
          name:'Comentario'
        }
      ];
  
      dispatch({
        type:FIELD_TYPE_TRAER_TODAS,
        payload:field_types
      });
      resolve();
    })
  }
 

  return (
    <FieldTypeContext.Provider
      value={{
        data:state.data,
        find
      }}>
        {props.children}
    </FieldTypeContext.Provider>
  );
}

export default FieldTypeState;