import React, { useContext, useEffect, useState } from 'react';
import FormServiceActions from '../forms/formServiceActions';
import ModalContent from '../Modal/ModalContent';
import ModalDispatcher from '../Modal/ModalDispatcher';
import ServiceOptionItem from './ServiceOptionItem';
import { ServiceOptionsContext } from '../../context/serviceOptions/ServiceOptionsContext';

const ServiceOptionList = ({service_id}) => {

  const [loading, setLoading] = useState(true);
  const [entityToModal, setEntityToModal] = useState({
    service_id,
    value:'',
    is_default:0,
    action:'create'
  });
  const [showForm, setShowForm] = useState(false);
  const {data:service_options_list, flag_execute, readByServiceId} = useContext(ServiceOptionsContext);

  useEffect(() => {
    getResources();
  }, []);

  useEffect(() => {
    if(flag_execute){
      setLoading(false);
    }
  }, [service_options_list])
  

  const getResources = async () => {
    readByServiceId(service_id);
  }

  const add = () => {
    setEntityToModal({
      service_id,
      value:'',
      is_default:0,
      action:'create'
    });
    setShowForm(true);
  }

  const edit = (id, service_id, value, is_default) => {
    setEntityToModal({
      id,
      service_id,
      value,
      is_default,
      action:'edit'
    });
    setShowForm(true);
  }

  const clearForm = () => {
    setShowForm(false);
  }
  
 
  const renderItems = () => {
    return service_options_list.map((option_item, key)=>(
      <ServiceOptionItem option_item={option_item} key={key} handleEditClick={edit}/>
    ));
  }

  return (
    loading ? <p>Cargando opciones...</p> :
    <div className='service_options_list my-4'>
      <hr/>
      <div className='d-flex justify-content-between align-items-center'>
        <span>Opciones cargadas para el servicio: </span>
        <ModalDispatcher class_name="btn btn-outline-primary" button_text="Agregar opción" handleClickFunction={()=>add()}/>
      </div>
      <br/>
      {service_options_list.length === 0 ? <div className='alert alert-warning'>No hay opciones cargadas</div> : renderItems()}

      <ModalContent modal_title="Opciones de servicio" onCloseHandler={() => clearForm()}>
        { showForm ? <FormServiceActions entity={entityToModal}/> : null }
      </ModalContent>
    </div>
  );
}

export default ServiceOptionList;