import { DATA_TYPE_LOADING, DATA_TYPE_TRAER_TODAS } from "../../types";

const DataTypeReducer = (state, action) => {
  switch (action.type) {
    case DATA_TYPE_LOADING:
      return {...state, loading:true, error:null}
    case DATA_TYPE_TRAER_TODAS:
      return {...state, loading:false, error:null, data:action.payload}
    default:
      return state;
  }
}

export default DataTypeReducer;