import React, { useContext, useEffect, useState } from 'react';
import FormDatoTecnico from '../../components/forms/formDatoTecnico';
import FormPropiedad from '../../components/forms/formPropiedad';
import FormServicio from '../../components/forms/formServicio';
import FormHeader from '../../components/forms/formHeader';
import FormImagenes from '../../components/forms/formImagenes';
import { PropiedadContext } from "../../context/propiedades/propiedadesContext";
import { DatoTecnicoContext } from "../../context/datoTecnico/datoContext";
import { ServiciosContext } from "../../context/servicios/serviciosContext";
import { ImagenesContext } from "../../context/imagenes/imagenesContext";
import { useHistory } from 'react-router';
import { CategoriasContext } from '../../context/categorias/categoriasContext';
import { OperacionesContext } from '../../context/operaciones/operacionesContext';
import { PartidosContext } from '../../context/partidos/partidosContext';
import { LocalidadesContext } from '../../context/localidades/localidadesContext';
import { BarriosContext } from '../../context/barrios/barriosContext';
import Swal from 'sweetalert2';

const EditPropiedad = (props) => {
    const {propiedad,traerUna,modificar:modificarPropiedad} = useContext(PropiedadContext);
    const {habilitarFormHeader,habilitarFormVarias} = useContext(ImagenesContext);
    const {filtrarPorIdLocalidad:filtrarBarrios} = useContext(BarriosContext);

    const [formValues, setFormValues] = useState({
        propiedad:{
            idCategoria:"",
            idOperacion:"",
            idPartido:"",
            idLocalidad:"",
            idBarrio:"",
            direccion:"",
            descripcion:"",
            precio:"",
            estado:"Disponible",
            mostrarEstado:"si",
            moneda:"dolar",
            lat:'',
            lon:'' 
        },
        datos_tecnicos:{
            s_cubierta:'',
            s_semicubierta:'',
            s_terreno:'',
            s_total:'',
            pileta:'Si',
            cochera:'Si',
            dormitorios:'0',
            u_medida:'metros cuadrados',
            baños:'0'
        },
        servicios:{
            luz:'',
            agua:'',
            calefaccion:'',
            telefono:'',
            gas:'',
            internet:'',
        }
    })
    const history = useHistory();

    useEffect(() => {
        if(propiedad){
            filtrarBarrios(propiedad.idLocalidad);
            setFormValues({
                propiedad:{
                    idCategoria:`${propiedad.idCategoria}`,
                    idOperacion:`${propiedad.idOperacion}`,
                    idPartido:`${propiedad.idPartido}`,
                    idLocalidad:`${propiedad.idLocalidad}`,
                    idBarrio:`${propiedad.idBarrio}`,
                    direccion:`${propiedad.direccion}`,
                    descripcion:`${propiedad.descripcion}`,
                    precio:propiedad.precio,
                    estado:`${propiedad.estado}`,
                    mostrarEstado:`${propiedad.mostrarEstado}`,
                    moneda:`${propiedad.moneda}`,
                    lat:`${propiedad.lat}`,
                    lon:`${propiedad.lon}` 
                },
                datos_tecnicos:{
                    cochera:propiedad.cochera,
                    dormitorios:`${propiedad.dormitorios}`,
                    pileta:propiedad.pileta,
                    s_cubierta:`${propiedad.s_cubierta}`,
                    s_semicubierta:`${propiedad.s_semicubierta}`,
                    s_terreno:`${propiedad.s_terreno}`,
                    s_total:propiedad.s_total || '0',
                    u_medida:propiedad.u_medida,
                    baños:`${propiedad.baños}`
                },
                servicios:{
                    agua:propiedad.agua,
                    calefaccion:propiedad.calefaccion,
                    gas:propiedad.gas,
                    idCasa:propiedad.idCasa,
                    internet:propiedad.internet,
                    luz:propiedad.luz,
                    telefono:propiedad.telefono,
                }
            });
        }else{
            traerUna(props.match.params.id);
        }
    }, [propiedad]);

    const handleChangePropiedad = e =>{
        setFormValues({
            ...formValues,
            propiedad:{
                ...formValues.propiedad,
                [e.target.name]:e.target.value
            }
        })
    };

    const handleChangeTecnico = e =>{
        setFormValues({
            ...formValues,
            datos_tecnicos:{
                ...formValues.datos_tecnicos,
                [e.target.name]:e.target.value
            }
        })
    };

    const handleChangeServicio = e =>{
        setFormValues({
            ...formValues,
            servicios:{
                ...formValues.servicios,
                [e.target.name]:e.target.value
            }
        })
    };

    const enviar = async () =>{
        //validar el form
        const validation = validarForm();
        if(typeof validation == "string"){
            Swal.fire("Error",`El campo ${validation} es obligatorio`,"error");
            return;
        }

        //enviar datos al server
        await modificarPropiedad(formValues,props.match.params.id);
        Swal.fire("Listo","Propiedad guardada", "success");
    }

    const validarForm = ()=>{
        for (const key in formValues.propiedad) {
            if(formValues.propiedad[key] == '') return key;
        }
        for (const key in formValues.datos_tecnicos) {
            if(formValues.datos_tecnicos[key] == '') return key;
        }
        for (const key in formValues.servicios) {
            if(formValues.servicios[key] == '') return key;
        }
        return true;
    }

    return (
        <div className="container pt-5">
            <h4>Formulario de modificación de propiedad</h4>
            <FormPropiedad formValues={formValues.propiedad} allFormValues={formValues} handleChange={handleChangePropiedad} setFormValues={setFormValues} id={props.match.params.id}/>
            <FormDatoTecnico formValues={formValues.datos_tecnicos} handleChange={handleChangeTecnico}/>
            {formValues.servicios.luz === '' ? null : <FormServicio formValues={formValues.servicios} handleChange={handleChangeServicio}/>}
            <div className="col-12 text-center mb-4">
                <button type="button" className="btn btn-success" onClick={enviar}>Editar propiedad</button>
            </div>
            <FormHeader id={props.match.params.id}/>
        </div>
    );
}
 
export default EditPropiedad;