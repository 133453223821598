import { SERVICES_INMUEBLE_TYPE_AGREGAR, SERVICES_INMUEBLE_TYPE_ELIMINAR, SERVICES_INMUEBLE_TYPE_ERROR, SERVICES_INMUEBLE_TYPE_LOADING, SERVICES_INMUEBLE_TYPE_TRAER_TODAS } from "../../types";

const serviceInInmuebleTypeReducer = (state,action) => {
    switch (action.type) {
        case SERVICES_INMUEBLE_TYPE_LOADING:
            return {
                ...state,
                loading:true,
                error:null
            }
        case SERVICES_INMUEBLE_TYPE_ERROR:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case SERVICES_INMUEBLE_TYPE_TRAER_TODAS:
            return {
                ...state,
                loading:false,
                error:null,
                data:action.payload
            }
        case SERVICES_INMUEBLE_TYPE_AGREGAR:
        case SERVICES_INMUEBLE_TYPE_ELIMINAR:
            return {
                ...state,
                loading:false,
                error:null
            }
        default:
            return state;
    }
}

export default serviceInInmuebleTypeReducer;