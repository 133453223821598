export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_OBTENER_USUARIO = 'AUTH_OBTENER_USUARIO';

export const BARRIOS_LOADING = 'BARRIOS_LOADING';
export const BARRIOS_ERROR = 'BARRIOS_ERROR';
export const BARRIOS_TRAER_TODOS = 'BARRIOS_TRAER_TODOS';
export const BARRIOS_TRAER_UNO = 'BARRIOS_TRAER_UNO';
export const BARRIOS_AGREGAR = 'BARRIOS_AGREGAR';
export const BARRIOS_MODIFICAR = 'BARRIOS_MODIFICAR';
export const BARRIOS_ELIMINAR = 'BARRIOS_ELIMINAR';
export const BARRIOS_FILTRAR = 'BARRIOS_FILTRAR';

export const LOCALIDADES_LOADING = 'LOCALIDADES_LOADING';
export const LOCALIDADES_ERROR = 'LOCALIDADES_ERROR';
export const LOCALIDADES_TRAER_TODAS = 'LOCALIDADES_TRAER_TODAS';
export const LOCALIDADES_TRAER_UNO = 'LOCALIDADES_TRAER_UNO';
export const LOCALIDADES_AGREGAR = 'LOCALIDADES_AGREGAR';
export const LOCALIDADES_MODIFICAR = 'LOCALIDADES_MODIFICAR';
export const LOCALIDADES_ELIMINAR = 'LOCALIDADES_ELIMINAR';
export const LOCALIDADES_FILTRAR = 'LOCALIDADES_FILTRAR';

export const PARTIDOS_LOADING = 'PARTIDOS_LOADING';
export const PARTIDOS_ERROR = 'PARTIDOS_ERROR';
export const PARTIDOS_TRAER_TODOS = 'PARTIDOS_TRAER_TODOS';
export const PARTIDOS_TRAER_UNO = 'PARTIDOS_TRAER_UNO';
export const PARTIDOS_AGREGAR = 'PARTIDOS_AGREGAR';
export const PARTIDOS_MODIFICAR = 'PARTIDOS_MODIFICAR';
export const PARTIDOS_ELIMINAR = 'PARTIDOS_ELIMINAR';

export const OPERACIONES_LOADING = 'OPERACIONES_LOADING';
export const OPERACIONES_ERROR = 'OPERACIONES_ERROR';
export const OPERACIONES_TRAER_TODAS = 'OPERACIONES_TRAER_TODAS';
export const OPERACIONES_TRAER_UNA = 'OPERACIONES_TRAER_UNA';
export const OPERACIONES_AGREGAR = 'OPERACIONES_AGREGAR';
export const OPERACIONES_MODIFICAR = 'OPERACIONES_MODIFICAR';
export const OPERACIONES_ELIMINAR = 'OPERACIONES_ELIMINAR';

export const CATEGORIAS_LOADING = 'CATEGORIAS_LOADING';
export const CATEGORIAS_ERROR = 'CATEGORIAS_ERROR';
export const CATEGORIAS_TRAER_TODAS = 'CATEGORIAS_TRAER_TODAS';
export const CATEGORIAS_TRAER_UNA = 'CATEGORIAS_TRAER_UNA';
export const CATEGORIAS_AGREGAR = 'CATEGORIAS_AGREGAR';
export const CATEGORIAS_MODIFICAR = 'CATEGORIAS_MODIFICAR';
export const CATEGORIAS_ELIMINAR = 'CATEGORIAS_ELIMINAR';

export const NOSOTROS_LOADING = 'NOSOTROS_LOADING';
export const NOSOTROS_ERROR = 'NOSOTROS_ERROR';
export const NOSOTROS_TRAER_INFO= 'NOSOTROS_TRAER_INFO';
export const NOSOTROS_MODIFICAR = 'NOSOTROS_MODIFICAR';

export const CONTACTO_LOADING = 'CONTACTO_LOADING';
export const CONTACTO_ERROR = 'CONTACTO_ERROR';
export const CONTACTO_TRAER_INFO= 'CONTACTO_TRAER_INFO';
export const CONTACTO_MODIFICAR = 'CONTACTO_MODIFICAR';

export const PROPIEDAD_LOADING = 'PROPIEDAD_LOADING';
export const PROPIEDAD_ERROR = 'PROPIEDAD_ERROR';
export const PROPIEDAD_TRAER_TODAS = 'PROPIEDAD_TRAER_TODAS';
export const PROPIEDAD_TRAER_MAS = 'PROPIEDAD_TRAER_MAS';
export const PROPIEDAD_TRAER_UNO = 'PROPIEDAD_TRAER_UNO';
export const PROPIEDAD_AGREGAR = 'PROPIEDAD_AGREGAR';
export const PROPIEDAD_MODIFICAR = 'PROPIEDAD_MODIFICAR';
export const PROPIEDAD_ELIMINAR = 'PROPIEDAD_ELIMINAR';
export const PROPIEDAD_UPDATE_PAGINACION = 'PROPIEDAD_UPDATE_PAGINACION';
export const PROPIEDAD_CAMBIAR_ESTADO = 'PROPIEDAD_CAMBIAR_ESTADO';
export const PROPIEDAD_MOSTRAR_OCULTAR_FORMULARIO = 'PROPIEDAD_MOSTRAR_OCULTAR_FORMULARIO';
export const PROPIEDAD_APLICAR_FILTRO = 'PROPIEDAD_APLICAR_FILTRO';
export const PROPIEDAD_RESTABLECER_FILTRO = 'PROPIEDAD_RESTABLECER_FILTRO';

export const DATOS_TECNICOS_LOADING = 'DATOS_TECNICOS_LOADING';
export const DATOS_TECNICOS_ERROR = 'DATOS_TECNICOS_ERROR';
export const DATOS_TECNICOS_TRAER_INFO = 'DATOS_TECNICOS_TRAER_INFO';
export const DATOS_TECNICOS_AGREGAR = 'DATOS_TECNICOS_AGREGAR';
export const DATOS_TECNICOS_MODIFICAR = 'DATOS_TECNICOS_MODIFICAR';
export const DATOS_TECNICOS_MOSTRAR_OCULTAR_FORMULARIO = 'DATOS_TECNICOS_MOSTRAR_OCULTAR_FORMULARIO';

export const SERVICIOS_LOADING = 'SERVICIOS_LOADING';
export const SERVICIOS_ERROR = 'SERVICIOS_ERROR';
export const SERVICIOS_TRAER_INFO = 'SERVICIOS_TRAER_INFO';
export const SERVICIOS_AGREGAR = 'SERVICIOS_AGREGAR';
export const SERVICIOS_MODIFICAR = 'SERVICIOS_MODIFICAR';
export const SERVICIOS_MOSTRAR_OCULTAR_FORMULARIO = 'SERVICIOS_MOSTRAR_OCULTAR_FORMULARIO';

export const IMAGENES_LOADING = 'IMAGENES_LOADING';
export const IMAGENES_ERROR = 'IMAGENES_ERROR';
export const IMAGENES_TRAER_TODAS = 'IMAGENES_TRAER_TODAS';
export const IMAGENES_TRAER_UNA = 'IMAGENES_TRAER_UNA';
export const IMAGENES_AGREGAR = 'IMAGENES_AGREGAR';
export const IMAGENES_MODIFICAR = 'IMAGENES_MODIFICAR';
export const IMAGENES_ELIMINAR = 'IMAGENES_ELIMINAR';
export const IMAGENES_MOSTRAR_OCULTAR_FORMULARIO_HEADER = 'IMAGENES_MOSTRAR_OCULTAR_FORMULARIO_HEADER';
export const IMAGENES_MOSTRAR_OCULTAR_FORMULARIO_VARIAS = 'IMAGENES_MOSTRAR_OCULTAR_FORMULARIO_VARIAS';


export const SERVICES_LOADING = 'SERVICES_LOADING';
export const SERVICES_ERROR = 'SERVICES_ERROR';
export const SERVICES_TRAER_TODAS = 'SERVICES_TRAER_TODAS';
export const SERVICES_TRAER_UNA = 'SERVICES_TRAER_UNA';
export const SERVICES_AGREGAR = 'SERVICES_AGREGAR';
export const SERVICES_MODIFICAR = 'SERVICES_MODIFICAR';
export const SERVICES_ELIMINAR = 'SERVICES_ELIMINAR';

export const SERVICES_INMUEBLE_TYPE_LOADING = 'SERVICES_INMUEBLE_TYPE_LOADING';
export const SERVICES_INMUEBLE_TYPE_ERROR = 'SERVICES_INMUEBLE_TYPE_ERROR';
export const SERVICES_INMUEBLE_TYPE_TRAER_TODAS = 'SERVICES_INMUEBLE_TYPE_TRAER_TODAS';
export const SERVICES_INMUEBLE_TYPE_TRAER_UNA = 'SERVICES_INMUEBLE_TYPE_TRAER_UNA';
export const SERVICES_INMUEBLE_TYPE_AGREGAR = 'SERVICES_INMUEBLE_TYPE_AGREGAR';
export const SERVICES_INMUEBLE_TYPE_MODIFICAR = 'SERVICES_INMUEBLE_TYPE_MODIFICAR';
export const SERVICES_INMUEBLE_TYPE_ELIMINAR = 'SERVICES_INMUEBLE_TYPE_ELIMINAR';

export const FIELD_TYPE_LOADING = 'FIELD_TYPE_LOADING';
export const FIELD_TYPE_ERROR = 'FIELD_TYPE_ERROR';
export const FIELD_TYPE_TRAER_TODAS = 'FIELD_TYPE_TRAER_TODAS';

export const DATA_TYPE_LOADING = 'DATA_TYPE_LOADING';
export const DATA_TYPE_ERROR = 'DATA_TYPE_ERROR';
export const DATA_TYPE_TRAER_TODAS = 'DATA_TYPE_TRAER_TODAS';

export const SERVICE_OPTIONS_LOADING = 'SERVICE_OPTIONS_LOADING';
export const SERVICE_OPTIONS_ERROR = 'SERVICE_OPTIONS_ERROR';
export const SERVICE_OPTIONS_TRAER = 'SERVICE_OPTIONS_TRAER';
export const SERVICE_OPTIONS_CREATE = 'SERVICE_OPTIONS_CREATE';
export const SERVICE_OPTIONS_UPDATE = 'SERVICE_OPTIONS_UPDATE';
export const SERVICE_OPTIONS_DELETE = 'SERVICE_OPTIONS_DELETE';
export const SERVICE_OPTIONS_READ = 'SERVICE_OPTIONS_READ';
