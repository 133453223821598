import React from 'react';

const FormDatoTecnico = (props) => {
    const {formValues,handleChange} = props;
    
    return (
        <div className="form-group" id="form-tecnico">
            <h6>Datos técnicos</h6>
            <div className="row">
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3 mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Superf. Cubierta</div>
                        </div>
                        <input type="text" name="s_cubierta" value={formValues.s_cubierta} onChange={handleChange} placeholder="Superficie cubierta en m2" className="form-control"/>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Super. Semicubierta</div>
                        </div>
                        <input type="text" onChange={handleChange} value={formValues.s_semicubierta} name="s_semicubierta" placeholder="Superficie semicubierta en m2" className="form-control"/>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3 mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Super. Terreno</div>
                        </div>
                        <input type="text" onChange={handleChange} value={formValues.s_terreno} name="s_terreno" placeholder="Superficie del terreno" className="form-control"/>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3 mb-2">
                        <div className="input-group-text">Sup. Total</div>
                        <input type="text" className="form-control" name="s_total" onChange={handleChange} value={formValues.s_total}/>
                        <div className="input-group-text">
                            <select onChange={handleChange} defaultValue={formValues.u_medida} name="u_medida">
                                <option value="metros cuadrados">M2</option>
                                <option value="hectareas">Hec.</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3 mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Pileta</div>
                        </div>
                        <select name="pileta" onChange={handleChange} defaultValue={formValues.pileta} className="form-control" id="">
                            <option value="-">Omitir dato</option>
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3 mb-2">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Cochera</div>
                        </div>
                        <select name="cochera" onChange={handleChange} defaultValue={formValues.cochera} className="form-control" id="">
                            <option value="-">Omitir dato</option>
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <input type="hidden" name="idCasa"/>
                    <input type="hidden" name="pass"/>
                    <div className="input-group mt-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Dormitorios</div>
                        </div>
                        <input type="text" name="dormitorios" onChange={handleChange} value={formValues.dormitorios} placeholder="Ingrese cantidad de dormitorios" className="form-control"/>
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="input-group mt-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">Baños</div>
                        </div>
                        <input type="text" name="baños" onChange={handleChange} value={formValues.baños} placeholder="Ingrese cantidad de baños" className="form-control"/>
                    </div>
                </div>
            </div>
            <br/><br/>
        </div>
    );
}
 
export default FormDatoTecnico;