import React from 'react'
import './ServiceItemStyle.css';

const ServiceItem = ({service_name, value, isAssigned, handleChange}) => {
    return <div className='service_item_card'>
        <input type="checkbox" name="services" value={value} onChange={handleChange} checked={isAssigned ? 'checked' : ''}/>
        <span>{service_name}</span>
    </div>
}


export default ServiceItem;