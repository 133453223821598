import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import Swal from 'sweetalert2';
import { ServicesContext } from "../../context/services/servicesContext";
import { DataTypeContext } from "../../context/dataTypes/DataTypeContext";
import { FieldTypeContext } from "../../context/fieldTypes/FieldTypeContext";
import { useHistory } from 'react-router';
import ServiceOptionList from '../ServiceOptions/ServiceOptionsList';

const FormService = (props) => {
    const [loading, setLoading] = useState(false); // loading general de la pantalla
    const [errorForm, setErrorForm] = useState(false);
    const [formValues, setFormValues] = useState({
        name:'',
        description:'',
        data_type_id: null,
        field_type_id: null
    });
    const [showServiceOptionList, setShowServiceOptionList] = useState(false);
    const {service, create, update, read} = useContext(ServicesContext);
    const {data:data_types, find: findDataTypes} = useContext(DataTypeContext);
    const {data:field_types, find: findFieldTypes} = useContext(FieldTypeContext);
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        loadResources();
    }, []);
    
    useEffect(() => {
        if(service && data_types.length > 0 && field_types.length > 0 && props.id){
            setFormValues({
                name:service.name,
                description:service.description,
                data_type_id:service.data_type_id,
                field_type_id:service.field_type_id,
                service_options:[]
            });
            if(service.field_type_id === 2){
                setShowServiceOptionList(true);
            }
            setLoading(false);
        }
    }, [service])
    
    const loadResources = async() => {
        await findDataTypes();
        await findFieldTypes();
        if(props.id){
            await read(props.id);
        }else{
            setLoading(false);
        }
    }

    const handleChange = event=>{
        setFormValues({
            ...formValues,
            [event.target.name]:event.target.value
        })
    };

    /**
     * Detecta el cambio de valor en selector de tipo de dato
     * @param {*} event 
     */
    const handleChangeFieldType = event => {
        const {value:field_type_id} = event.target;
        const field_type_selected = field_types.filter(field_type => field_type.id == field_type_id)[0];
        switch (field_type_selected.code) {
            case 'SELECT': // CAMBIAR POR UNA VARIABLE!!
                showServiceListOptions();
                break;
            default:
                hideServiceListOptions();
                break;
        }
        handleChange(event);
    }

    const showServiceListOptions = () => {
        setShowServiceOptionList(true);
    };

    const hideServiceListOptions = () => {
        setShowServiceOptionList(false);
    };

    const verificar = ()=>{
        if(formValues.name.trim()==='' || formValues.description.trim() === ''){
            setErrorForm('Completa todo los campos');
            return false;
        }
        setErrorForm(false);
        return true;
    }

    const handleSubmit = async event=>{
        event.preventDefault();
        if(!verificar()){
            return false;
        }
        
        if(props.id){
            await update(formValues,props.id);
        }else{
            await create(formValues);
        }
        Swal.fire('Listo','','success').then(()=>history.push('/services'))
    }

    if(errorForm){
        let err = errorForm;
        Swal.fire('Error',err,'error').then(()=>setErrorForm(false));
    }

    return (
        loading ? <Loader/> :
        <div className="container mt-5">
            <h3>Formulario de edición/alta de un servicio</h3>
            <br/>
            <form className="form-group" onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-12 col-md-6 my-2'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    Nombre del servicio
                                </div>
                            </div>
                            <input type="text" className="form-control" value={formValues.name} placeholder="Nombre" onChange={handleChange} name="name" required/>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 my-2'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    Descripción del servicio
                                </div>
                            </div>
                            <input type="text" className="form-control" value={formValues.description} placeholder="Descripcion" onChange={handleChange} name="description" required/>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 my-2'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    Tipo de dato
                                </div>
                            </div>
                            <select className="form-control" name="data_type_id" id="field_type_id" onChange={handleChange} defaultValue={formValues.data_type_id}>
                                <option value="null">-- Seleccionar --</option>
                                {data_types.map(data_type=>(
                                    <option key={data_type.code} value={data_type.id}>{data_type.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 my-2'>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    Forma de carga
                                </div>
                            </div>
                            <select className="form-control" name="field_type_id" id="field_type_id" onChange={service ? handleChangeFieldType : handleChange} defaultValue={formValues.field_type_id}>
                                <option value="null">-- Seleccionar --</option>
                                {field_types.map(field_type=>(
                                    <option key={field_type.code} value={field_type.id}>{field_type.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-12 my-2'>
                        <input type="submit" className="btn btn-info w-100" value="Guardar"/>
                    </div>
                </div>
            </form>

            {showServiceOptionList ? <ServiceOptionList service_id={service.id}/> : null}

        </div>
    );
}
 
export default FormService;