import React,{useReducer} from 'react';
import servicesReducer from './servicesReducer';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';
import { SERVICES_AGREGAR, SERVICES_ELIMINAR, SERVICES_ERROR, SERVICES_LOADING, SERVICES_MODIFICAR, SERVICES_TRAER_TODAS, SERVICES_TRAER_UNA } from '../../types';
import { ServicesContext } from './servicesContext';

const ServicesState = (props)=>{

    const INITIAL_STATE = {
        data:[],
        service:null,
        loading:false,
        error:null
    }

    const [state, dispatch] = useReducer(servicesReducer, INITIAL_STATE);

    const find = async()=>{
        dispatch({
            type:SERVICES_LOADING
        })
        try {
            if(localStorage.getItem("token")){
                tokenAuth(localStorage.getItem("token"));
            }
            const res = await clienteAxios.get('/services');
            const {data:{data:services}} = res;
            dispatch({
                type:SERVICES_TRAER_TODAS,
                payload:services
            })
        } catch (error) {
            dispatch({
                type:SERVICES_ERROR,
                payload:error.response.data
            })
        }
    }

    const read = async(id)=>{
        dispatch({
            type:SERVICES_LOADING
        })
        try {
            if(localStorage.getItem('token')){
                tokenAuth(localStorage.getItem('token'));
            }
            const res = await clienteAxios.get(`/services/${id}`);
            const {data:{data}} = res;
            dispatch({
                type:SERVICES_TRAER_UNA,
                payload:data[0]
            })
        } catch (error) {
            dispatch({
                type:SERVICES_ERROR,
                payload:error.response.data
            })
        }
    }

    const update = async (data,id)=>{
        dispatch({
            type:SERVICES_LOADING
        })
        try {
            if(localStorage.getItem('token')){
                tokenAuth(localStorage.getItem('token'));
            }
            await clienteAxios.put(`/services/${id}`,data);
            dispatch({
                type:SERVICES_MODIFICAR
            })
            return;
        } catch (error) {
            dispatch({
                type:SERVICES_ERROR,
                payload:error.response.data
            })
        }
    }

    const create = async data=>{
        dispatch({
            type:SERVICES_LOADING
        })
        try {
            if(localStorage.getItem('token')){
                tokenAuth(localStorage.getItem('token'));
            }
            await clienteAxios.post('/services',data);
            dispatch({type:SERVICES_AGREGAR});
            return;
        } catch (error) {
            dispatch({
                type:SERVICES_ERROR,
                payload:error.response.data
            })
        }
    }

    const remove = async id=>{
        dispatch({
            type:SERVICES_LOADING
        })
        try {
            if(localStorage.getItem('token')){
                tokenAuth(localStorage.getItem('token'));
            }
            await clienteAxios.delete(`/services/${id}`);
            dispatch({type:SERVICES_ELIMINAR});
            return;
        } catch (error) {
            dispatch({
                type:SERVICES_ERROR,
                payload:error.response.data
            })
        }
    }



    return(
        <ServicesContext.Provider
            value={{
                data:state.data,
                loading:state.loading,
                service:state.service,
                error:state.error,
                find,
                read,
                create,
                update,
                remove
            }}
        >
            {props.children}
        </ServicesContext.Provider>
    )
}

export default ServicesState;