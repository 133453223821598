import React, { useReducer } from 'react';
import ServiceOptionsReducer from './ServiceOptionsReducer';
import {ServiceOptionsContext} from './ServiceOptionsContext';
import { SERVICE_OPTIONS_CREATE, SERVICE_OPTIONS_DELETE, SERVICE_OPTIONS_ERROR, SERVICE_OPTIONS_LOADING, SERVICE_OPTIONS_READ, SERVICE_OPTIONS_TRAER, SERVICE_OPTIONS_UPDATE } from "../../types";
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';

const ServiceOptionsState = props => {
  const INITIAL_STATE = {
    data:[],
    selected:null,
    loading:false,
    error:null,
    flag_execute:false // flag para indicar si se ejecuto una petición
  }

  const [state, dispatch] = useReducer(ServiceOptionsReducer, INITIAL_STATE);

  const readByServiceId = async service_id => {
    dispatch({
      type:SERVICE_OPTIONS_LOADING
    })

    try {
      if(localStorage.getItem('token')){
        tokenAuth(localStorage.getItem('token'));
      }
      const res = await clienteAxios.get('/service_options/service/'+service_id);
      const {data:{ok,data,message}} = res;
      if(!ok){
        return dispatch({
          type:SERVICE_OPTIONS_ERROR,
          payload:message
        })
      }
      return dispatch({
        type: SERVICE_OPTIONS_TRAER,
        payload: data
      });
    } catch (error) {
      dispatch({
        type:SERVICE_OPTIONS_ERROR,
        payload:error.message
      })
    }
  }

  const read = async service_option_id => {
    dispatch({
      type:SERVICE_OPTIONS_LOADING
    })

    try {
      if(localStorage.getItem('token')){
        tokenAuth(localStorage.getItem('token'));
      }
      const res = await clienteAxios.get('/service_options/'+service_option_id);
      const {data:{ok,data,message}} = res;
      if(!ok){
        return dispatch({
          type:SERVICE_OPTIONS_ERROR,
          payload:message
        })
      }
      return dispatch({
        type: SERVICE_OPTIONS_READ,
        payload: data[0]
      });
    } catch (error) {
      dispatch({
        type:SERVICE_OPTIONS_ERROR,
        payload:error.message
      })
    }
  }

  const update = async (service_option_id, entity) => {
    dispatch({
      type:SERVICE_OPTIONS_LOADING
    })

    try {
      if(localStorage.getItem('token')){
        tokenAuth(localStorage.getItem('token'));
      }
      const res = await clienteAxios.put('/service_options/'+service_option_id, entity);
      const {data:{ok,data,message}} = res;
      if(!ok){
        return dispatch({
          type:SERVICE_OPTIONS_ERROR,
          payload:message
        })
      }
      return dispatch({
        type: SERVICE_OPTIONS_UPDATE,
        payload: data
      });
    } catch (error) {
      dispatch({
        type:SERVICE_OPTIONS_ERROR,
        payload:error.message
      })
    }
  }

  const create = async (entity) => {
    dispatch({
      type:SERVICE_OPTIONS_LOADING
    })

    try {
      if(localStorage.getItem('token')){
        tokenAuth(localStorage.getItem('token'));
      }
      const res = await clienteAxios.post('/service_options', entity);
      const {data:{ok,data,message}} = res;
      if(!ok){
        return dispatch({
          type:SERVICE_OPTIONS_ERROR,
          payload:message
        })
      }
      return dispatch({
        type: SERVICE_OPTIONS_CREATE,
        payload: data
      });
    } catch (error) {
      dispatch({
        type:SERVICE_OPTIONS_ERROR,
        payload:error.message
      })
    }
  }

  const remove = async id => {
    dispatch({
      type:SERVICE_OPTIONS_LOADING
    })

    try {
      if(localStorage.getItem('token')){
        tokenAuth(localStorage.getItem('token'));
      }
      const res = await clienteAxios.delete('/service_options/'+id);
      const {data:{ok,data,message}} = res;
      if(!ok){
        return dispatch({
          type:SERVICE_OPTIONS_ERROR,
          payload:message
        })
      }
      return dispatch({
        type: SERVICE_OPTIONS_DELETE,
        payload: data
      });
    } catch (error) {
      dispatch({
        type:SERVICE_OPTIONS_ERROR,
        payload:error.message
      })
    }
  }
 

  return (
    <ServiceOptionsContext.Provider
      value={{
        data:state.data,
        selected:state.selected,
        flag_execute:state.flag_execute,
        readByServiceId,
        read,
        update,
        create,
        remove
      }}>
        {props.children}
    </ServiceOptionsContext.Provider>
  );
}

export default ServiceOptionsState;