import React from 'react';
import FormService from '../../components/forms/formService';

const ServiceEditPage = (props) => {
    return (
        <>
            <div className='container mt-3'>
                <a href='/services' className='btn btn-warning'>Volver al listado</a>
            </div>
            <FormService id={props.match.params.id}/>
        </>
    );
}
 
export default ServiceEditPage;