import React from 'react';

const FormServicio = (props) => {
    const {formValues,handleChange} = props;

    return (
        <div className="form-group" id="form-servicio">
            <h6>Servicios</h6>
            <div className="row text-center">
                <div className="input-group col-12 col-md-4">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Electricidad</div>
                    </div>
                    <select className="form-control" name="luz" defaultValue={formValues.luz} onChange={handleChange}>
                        <option value="">Seleccione una opción</option>
                        <option value="no">No</option>
                        <option value="si">Si</option>
                    </select>
                </div>
                <div className="input-group col-12 col-md-4">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Agua</div>
                    </div>
                    <select className="form-control" defaultValue={formValues.agua} onChange={handleChange} name="agua">
                        <option value="">Seleccione una opción</option>
                        <option value="corriente">Corriente</option>
                        <option value="pozo">Pozo</option>
                    </select>
                </div>
                <div className="input-group col-12 col-md-4">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Calefacción</div>
                    </div>
                    <select className="form-control" defaultValue={formValues.calefaccion} name="calefaccion" onChange={handleChange}>
                        <option value="">Seleccione una opción</option>
                        <option value="no">No</option>
                        <option value="si">Si</option>
                    </select>
                </div>
            </div>
            <div className="row mt-4">
                <div className="input-group col-12 col-md-4">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Telefono</div>
                    </div>
                    <select className="form-control" defaultValue={formValues.telefono} name="telefono" onChange={handleChange}>
                        <option value="">Seleccione una opción</option>
                        <option value="no">No</option>
                        <option value="si">Si</option>
                    </select>
                </div>
                <div className="input-group col-12 col-md-4">
                    <div className="input-group-prepend">
                    <div className="input-group-text">Gas</div>
                    </div>
                    <select className="form-control" defaultValue={formValues.gas} name="gas" onChange={handleChange}>
                        <option value="">Seleccione una opción</option>
                        <option value="envasado">Envasado</option>
                        <option value="natural">Natural</option>
                    </select>
                </div>
                <div className="input-group col-12 col-md-4">
                    <div className="input-group-prepend">
                    <div className="input-group-text">Internet</div>
                    </div>
                    <select className="form-control" defaultValue={formValues.internet} name="internet" onChange={handleChange}>
                        <option value="">Seleccione una opción</option>
                        <option value="no">No</option>
                        <option value="si">Si</option>
                    </select>
                </div>
            </div>
            <br/><br/>
        </div>
    );
}
 
export default FormServicio;