import React, { useReducer } from 'react';
import DataTypeReducer from './DataTypeReducer';
import {DataTypeContext} from './DataTypeContext';
// import clienteAxios from '../../config/axios';
import { DATA_TYPE_LOADING, DATA_TYPE_TRAER_TODAS } from "../../types";

const DataTypeState = props => {
  const INITIAL_STATE = {
    data:[],
    loading:false,
    error:null
  }

  const [state, dispatch] = useReducer(DataTypeReducer, INITIAL_STATE);

  const find = () => {
    // simulo un proceso asincronico porque no voy contra ninguna api
    return new Promise((resolve,reject)=>{ 
      dispatch({
        type:DATA_TYPE_LOADING
      })
      // reemplazar por consulta a ms
      const data_types = [
        {
          id:1,
          code:'TEXT',
          name:'texto'
        },
        {
          id:2,
          code:'NUMBER',
          name:'numerico'
        }
      ];
  
      dispatch({
        type:DATA_TYPE_TRAER_TODAS,
        payload:data_types
      });
      resolve();
    });
  }
 

  return (
    <DataTypeContext.Provider
      value={{
        data:state.data,
        find
      }}>
        {props.children}
    </DataTypeContext.Provider>
  );
}

export default DataTypeState;