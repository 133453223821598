import { SERVICES_AGREGAR, SERVICES_ELIMINAR, SERVICES_ERROR, SERVICES_LOADING, SERVICES_MODIFICAR, SERVICES_TRAER_TODAS, SERVICES_TRAER_UNA } from "../../types";

const servicesReducer = (state,action)=>{
    switch (action.type) {
        case SERVICES_LOADING:
            return {...state,loading:true}
        case SERVICES_ERROR:
            return {...state,loading:false,error:action.payload}
        case SERVICES_TRAER_TODAS:
            return {...state,data:action.payload,loading:false,error:null,service:null}
        case SERVICES_TRAER_UNA:
            return {...state,service:action.payload,loading:false,error:null}
        case SERVICES_AGREGAR:
        case SERVICES_MODIFICAR:
        case SERVICES_ELIMINAR:
            return {...state,loading:false,error:null}
        default:
            return state;
    }
}

export default servicesReducer;