import { SERVICE_OPTIONS_LOADING, SERVICE_OPTIONS_ERROR, SERVICE_OPTIONS_TRAER, SERVICE_OPTIONS_CREATE, SERVICE_OPTIONS_UPDATE, SERVICE_OPTIONS_DELETE, SERVICE_OPTIONS_READ } from "../../types";

const ServiceOptionsReducer = (state, action) => {
  switch (action.type) {
    case SERVICE_OPTIONS_LOADING:
      return {...state, loading:true, error:null, flag_execute:false, selected:null}
    case SERVICE_OPTIONS_ERROR:
      return {...state, loading:false, error:action.payload, flag_execute: true}
    case SERVICE_OPTIONS_TRAER:
      return {...state, loading:false, error:null, data:action.payload, flag_execute:true}
    case SERVICE_OPTIONS_READ:
      return {...state, loading:false, error:null, flag_execute:true, selected:action.payload}
    case SERVICE_OPTIONS_CREATE:
    case SERVICE_OPTIONS_UPDATE:
    case SERVICE_OPTIONS_DELETE:
      return {...state, loading:false, error:null, flag_execute:true, selected:null}
    default:
      return state;
  }
}

export default ServiceOptionsReducer;